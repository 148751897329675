import Vue from "vue";
import VueRouter from "vue-router";

// 引入组件
import tsIdphoto from "../pages/ts_idphoto/pc_idphoto/pc_idphoto.vue";
import tsMIdphoto from "../pages/ts_idphoto/m_idphoto/m_idphoto.vue";
import tsVideoRestore from "../pages/ts_video_restore/pc_video_restore/pc_video_restore.vue";
import tsMVideoRestore from "../pages/ts_video_restore/m_video_restore/m_video_restore.vue";
import tsRephoto from "../pages/ts_rephoto/ts_rephoto/ts_rephoto.vue";
import tsZuimei from "../pages/ts_zuimei/m_zuimei/m_zuimei.vue";

// 要告诉 vue 使用 vueRouter
Vue.use(VueRouter);

const routes = [
    // {
    //     path: "/easy_idphoto",
    //     name: 'easy_idphoto',
    //     component: tsIdphoto,
    //     meta: {
    //         title: "好用证件照",
    //         content: {
    //             keywords: '好用证件照',
    //             description: '好用证件照',
    //         },
    //     }
    // },
    // {
    //     path: "/easy_idphoto.html",
    //     name: 'easy_idphoto',
    //     component: tsIdphoto,
    //     meta: {
    //         title: "好用证件照",
    //         content: {
    //             keywords: '好用证件照',
    //             description: '好用证件照',
    //         },
    //     }
    // },
    // {
    //     path: "/measy_idphoto",
    //     name: 'measy_idphoto',
    //     component: tsMIdphoto,
    //     meta: {
    //         title: "好用证件照",
    //         content: {
    //             keywords: '好用证件照',
    //             description: '好用证件照',
    //         },
    //     }
    // },
    // {
    //     path: "/measy_idphoto.html",
    //     name: 'measy_idphoto',
    //     component: tsMIdphoto,
    //     meta: {
    //         title: "好用证件照",
    //         content: {
    //             keywords: '好用证件照',
    //             description: '好用证件照',
    //         },
    //     }
    // },
    {
        path: "/ai_video_restore",
        name: 'ai_video_restore',
        component: tsVideoRestore,
        meta: {
            title: "照片视频恢复",
            content: {
                keywords: '照片视频恢复',
                description: '照片视频恢复',
            },
        }
    },
    {
        path: "/ai_video_restore.html",
        name: 'ai_video_restore',
        component: tsVideoRestore,
        meta: {
            title: "照片视频恢复",
            content: {
                keywords: '照片视频恢复',
                description: '照片视频恢复',
            },
        }
    },
    {
        path: "/m_ai_videorestore",
        name: 'm_ai_videorestore',
        component: tsMVideoRestore,
        meta: {
            title: "照片视频恢复",
            content: {
                keywords: '照片视频恢复',
                description: '照片视频恢复',
            },
        }
    },
    {
        path: "/m_ai_videorestore.html",
        name: 'm_ai_videorestore',
        component: tsMVideoRestore,
        meta: {
            title: "照片视频恢复",
            content: {
                keywords: '照片视频恢复',
                description: '照片视频恢复',
            },
        }
    },
    {
        path: "/ts_rephoto",
        name: 'ts_rephoto',
        component: tsRephoto,
        meta: {
            title: "照片修复软件",
            content: {
                keywords: '照片修复软件',
                description: '照片修复软件',
            },
        }
    },
    {
        path: "/ts_rephoto.html",
        name: 'ts_rephoto',
        component: tsRephoto,
        meta: {
            title: "照片修复软件",
            content: {
                keywords: '照片修复软件',
                description: '照片修复软件',
            },
        }
    },
    {
        path: "/ts_zuimei",
        name: 'ts_zuimei',
        component: tsZuimei,
        meta: {
            title: "高清最美证件照",
            content: {
                keywords: '高清最美证件照',
                description: '高清最美证件照',
            },
        }
    },


    // 重定向
    {
        path: '/',
        redirect: '/ts_zuimei'
    }
]

var router = new VueRouter({
    mode: 'history',
    routes
})
export default router;
<template>
  <div class="haoyong-wrap">
    <div class="hy-banner">
      <div class="hy-banner-left">
        <div class="hy-logo"></div>
        <div class="hy-txt">像自拍一样简单好看</div>
      </div>
    </div>

    <!-- main start -->
    <div class="hy-top-wrap">
      <div class="hy-top">
        <div class="hy-top-left"></div>
        <div class="hy-top-main">
          <div class="hy-top-title">好用证件照APP</div>
          <div class="hy-top-txt">让拍证件照像自拍一样简单又好看</div>
          <div class="hy-top-load">
            <!-- <div class="hy-top-qrcode"></div> -->
            <!-- <div class="hy-top-btns">
              <div class="hy-top-btn ios">iOS下载</div>
              <div class="hy-top-btn">安卓下载</div>
            </div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="hy-main">
      <div class="hy-list">
        <div class="hy-main-text">
          <div class="hy-main-number"></div>
          <div class="hy-main-title">发丝级抠图技术</div>
          <div class="hy-main-txt">自动识别发丝，抠图效果更显真实</div>
        </div>
        <div class="hy-main-pic"></div>
      </div>
    </div>

    <div class="hy-main">
      <div class="hy-list second">
        <div class="hy-main-pic"></div>
        <div class="hy-main-text">
          <div class="hy-main-number"></div>
          <div class="hy-main-title">自然美颜 拍出最美照片</div>
          <div class="hy-main-txt">智能美颜算法，让证件照合格又好看</div>
          <div class="hy-main-pros">
            <div class="hy-main-pro">自然嫩肤</div>
            <div class="hy-main-pro">磨皮美白</div>
            <div class="hy-main-pro">大眼亮眼</div>
            <div class="hy-main-pro">美颜瘦脸</div>
          </div>
        </div>
      </div>
    </div>

    <div class="hy-main">
      <div class="hy-list third">
        <div class="hy-main-text">
          <div class="hy-main-number"></div>
          <div class="hy-main-title">背景色任意切换</div>
          <div class="hy-main-txt">背景色卡随意切换，满足证件照需求</div>
          <div class="hy-main-demos">
            <div class="hy-main-demo"></div>
            <div class="hy-main-demo red"></div>
            <div class="hy-main-demo white"></div>
          </div>
        </div>
        <div class="hy-main-pic"></div>
      </div>
    </div>

    <div class="hy-main">
      <div class="hy-list four">
        <div class="hy-main-pic"></div>
        <div class="hy-main-text">
          <div class="hy-main-number"></div>
          <div class="hy-main-title">可制作500+种证件照</div>
          <div class="hy-main-txt">电子照直接保存 纸质版全国包邮</div>
          <div class="hy-main-tags">
            <div>
              <span>一寸</span>
              <span>二寸</span>
              <span>大二寸</span>
              <span>驾驶证</span>
            </div>
            <div>
              <span>社保证</span>
              <span>计算机等级考试</span>
              <span>英语四六级</span>
            </div>
            <div>
              <span>共计五百多种证件照规格</span>
              <span>可自定义</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- main end -->

    <div class="haoyong-footer">
      <div class="copyright">
        Copyright@2020苏州图说科技有限公司
        <a
          href="https://beian.miit.gov.cn"
          target="_blank"
        >苏ICP备20046747号-1</a>
      </div>
      <div class="addr">地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室</div>
      <div class="contact">电话：0512-87816685 &nbsp;&nbsp;&nbsp;&nbsp; 邮箱：feedback@techtushuo.com</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ts_idphoto",
  data() {
    return {};
  },
  mounted() {
    let ua = navigator.userAgent.toLowerCase();
    if (/mobile|android|iphone|ipad|phone/i.test(ua)) {
      this.$router
        .push({
          name: "measy_idphoto",
        })
        .catch((err) => {
          err;
        });
    }
  },
  methods: {
    anzhuoDown() {
      //   location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.magiclogo";
      window.open(
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.magiclogo"
      );
    },
  },
};
</script>
<style lang="less" scoped>
// @import "../../../styles/idphoto/m_app.less";
@import "./pc_idphoto.less";
</style>
<template>
  <div class="rephoto-wrap" @mousedown="stopImgDrag">
    <div class="rephoto-banner">
      <div class="rephoto-banner-left">
        <div class="rephoto-banner-logo"></div>
        <div class="rephoto-banner-txt pc-show">深度全面扫描 一键恢复</div>
        <div class="rephoto-banner-txt phone-show"></div>
      </div>
    </div>
    <div class="rephoto-top-wrap pc-show">
      <div class="rephoto-top">
        <div class="rephoto-top-pic"></div>
        <div class="rephoto-top-txt">
          <div class="rephoto-top-title">照片修复软件</div>
          <div class="rephoto-pros">
            <div class="rephoto-pros-list">旧照片修复</div>
            <div class="rephoto-pros-list">照片修补</div>
            <div class="rephoto-pros-list">黑白照上色</div>
            <div class="rephoto-pros-list">风格转换</div>
          </div>
          <!-- <div class="rephoto-top-load">
            <div class="rephoto-top-qrcode"></div>
            <div class="rephoto-top-btns">
              <div class="rephoto-top-btn ios">iOS下载</div>
              <div class="rephoto-top-btn">安卓下载</div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
    <div class="rephoto-top-wrap phone-show">
      <div class="rephoto-top-phone">
        <div class="top-txt">AI智能 一键修复</div>
        <div class="top-btn">立即下载</div>
      </div>
    </div>

    <div class="rephoto-content-wrap">
      <div class="rephoto-content-list bg2">
        <div class="rephoto-list-main">
          <div class="rephoto-list-txt">
            <div class="rephoto-list-title">专业修复老照片</div>
            <div class="rephoto-list-pro">专业恢复旧照，低像素，网络模糊 图中五官模糊的照片</div>
          </div>

          <!-- <div class="photo-pic-top">
              <div class="photo-old" :style="{width: reColor.oldPicWidth}" @click="clickReColor">
                <img :src="reColor.oldpic" :style="{width: reColor.wrapWidth+'px'}" alt />
                <span>修复前</span>
              </div>

              <div
                class="photo-change pc-show"
                :style="{left: reColor.btnLeft}"
                @mousedown="moveReColor"
              ></div>

              <div
                class="photo-change phone-show"
                :style="{left: reColor.btnLeft}"
                id="reFineId"
                @touchstart="down"
                @touchmove="moveReColorMobile"
                @touchend="end"
              ></div>

              <div class="photo-new" :style="{width: reColor.newPicWidth}" @click="clickReColor">
                <img :src="reColor.newpic" :style="{width: reColor.wrapWidth+'px'}" alt />
                <span>修复后</span>
              </div>
          </div>-->

          <!--end -->

          <div class="rephoto-list-box pc-show" id="pic1Wrap">
            <pics-drag :reObj="reNew" @clickReFn="clickReNew" @moveReFn="moveReNew"></pics-drag>
          </div>
          <div class="rephoto-list-box phone-show" id="pic3Wrap">
            <pics-drag
              :reObj="reNew"
              @clickReFn="clickReNew"
              @end="end"
              @down="down"
              @moveReFnMobile="moveReNewMobile"
            ></pics-drag>
          </div>
        </div>
      </div>
      <div class="rephoto-content-list bg3">
        <div class="rephoto-list-main">
          <div class="rephoto-list-txt fr">
            <div class="rephoto-list-title">黑白照片上色</div>
            <div class="rephoto-list-pro pc-show">把陈旧的老照片还原当年的色彩 人像/风景均可还原</div>
            <div class="rephoto-list-pro phone-show">把陈旧的老照片还原当年的色彩</div>
          </div>
          <div class="rephoto-list-box fl">
            <div class="rephoto-list-box pc-show" id="pic2Wrap">
              <pics-drag :reObj="reColor" @clickReFn="clickReColor" @moveReFn="moveReColor"></pics-drag>
            </div>
            <div class="rephoto-list-box phone-show" id="pic4Wrap">
              <pics-drag
                :reObj="reColor"
                @clickReFn="clickReColor"
                @end="end"
                @down="down"
                @moveReFnMobile="moveReColorMobile"
              ></pics-drag>
            </div>
          </div>
        </div>
      </div>
      <div class="rephoto-content-list bg4">
        <div class="rephoto-list-main">
          <div class="rephoto-list-txt">
            <div class="rephoto-list-title">照片修补 去除杂物</div>
            <div class="rephoto-list-pro">手动擦出多余部分，智能修补复原</div>
          </div>
          <div class="rephoto-list-box gif">
            <img src="../../../static/imgs/rephoto/rephoto-new.gif" alt />
          </div>
        </div>
      </div>
      <div class="rephoto-content-list bg5">
        <div class="rephoto-list-main">
          <div class="rephoto-list-txt fr">
            <div class="rephoto-list-title">照片风格转换</div>
            <div class="rephoto-list-pro center">自由选择滤镜，改变照片风格</div>
          </div>
          <div class="rephoto-list-box fl phone">
            <img src="../../../static/imgs/rephoto/pic_fengge.png" alt />
          </div>
        </div>
      </div>
    </div>

    <div class="rephoto-footer">
      <div class="pc-show">
        <div class="copyright">
          Copyright@2020苏州图说科技有限公司
          <a
            href="https://beian.miit.gov.cn"
            target="_blank"
          >苏ICP备20046747号-1</a>
        </div>
        <div class="addr">地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室</div>
        <div class="contact">电话：0512-87816685 &nbsp;&nbsp;&nbsp;&nbsp; 邮箱：feedback@techtushuo.com</div>
      </div>
      <div class="phone-show">
        联系电话：0512-87816685
        <br />联系邮箱：feedback@techtushuo.com
        <br />联系地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
        <br />Copyright@2020苏州图说科技有限公司
      </div>
    </div>
  </div>
</template>
<script>
import PicsDrag from "./pics-drag.vue";
export default {
  name: "ts_idphoto",
  components: {
    PicsDrag,
  },
  data() {
    return {
      reNew: {
        oldpic: require("../../../static/imgs/rephoto/pic_xiufu.png"),
        newpic: require("../../../static/imgs/rephoto/pic_xiufu2.png"),
        btnLeft: "50%",
        wrapWidth: "100%",
        oldPicWidth: "50%",
        newPicWidth: "50%",
        changeId: "renewId",
      },
      reColor: {
        oldpic: require("../../../static/imgs/rephoto/pic_shangse.png"),
        newpic: require("../../../static/imgs/rephoto/pic_shangse2.png"),
        btnLeft: "50%",
        wrapWidth: "100%",
        oldPicWidth: "50%",
        newPicWidth: "50%",
        changeId: "recolorId",
      },

      flags: false,
      position: {
        x: 0,
        y: 0,
      },
      nx: 0,
      dx: 0,
    };
  },
  mounted() {
    let ua = navigator.userAgent.toLowerCase();
    if (/mobile|android|iphone|ipad|phone/i.test(ua)) {
      let reNewPhoneObj = this.initBtnPos("pic3Wrap");
      this.reNew.wrapWidth = reNewPhoneObj.width;
      let reColorPhoneObj = this.initBtnPos("pic4Wrap");
      this.reColor.wrapWidth = reColorPhoneObj.width;
    } else {
      let reNewObj = this.initBtnPos("pic1Wrap");
      this.reNew.wrapWidth = reNewObj.width;
      let reColorObj = this.initBtnPos("pic2Wrap");
      this.reColor.wrapWidth = reColorObj.width;
    }
  },
  methods: {
    anzhuoDown() {
      //   location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.magiclogo";
      window.open(
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.magiclogo"
      );
    },
    stopImgDrag(e) {
      e.target.onmousedown = function (e) {
        e.preventDefault();
      };
    },
    initBtnPos(id) {
      let maxWidth = document.getElementById(id).offsetWidth;
      return {
        width: maxWidth,
        left: maxWidth / 2 + "px",
      };
    },
    move(e, obj) {
      //获取目标元素
      let oDiv = e.target;
      //算出鼠标相对元素的位置
      let disX = e.clientX - oDiv.offsetLeft;
      document.onmousemove = (e) => {
        //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        let maxWidth = obj.wrapWidth;
        //绑定元素位置到positionX和positionY上面
        if (left < 0 || left > maxWidth) return;
        //移动当前元素
        obj.btnLeft = left + "px";
        //修改老图宽度
        obj.oldPicWidth = left + "px";
        //修改新图宽度
        obj.newPicWidth = maxWidth - left + "px";
      };
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    clickImg(e, obj) {
      let left = e.offsetX;
      // 修改拖拽图标位置
      obj.btnLeft = left + "px";
      //修改老图宽度
      obj.oldPicWidth = left + "px";
      //修改新图宽度
      obj.newPicWidth = obj.wrapWidth - left + "px";
    },
    clickReNew(e) {
      this.clickImg(e, this.reNew);
    },
    clickReColor(e) {
      this.clickImg(e, this.reColor);
    },
    moveReNew(e) {
      this.move(e, this.reNew);
    },
    moveReColor(e) {
      this.move(e, this.reColor);
    },
    down(event, id) {
      let moveDiv = document.getElementById(id);
      console.log(moveDiv.offsetLeft);
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      // console.log(touch.clientX);
      this.position.x = touch.clientX;
      this.dx = moveDiv.offsetLeft;
      this.flags = true;
    },
    mobileMove(e, obj) {
      if (this.flags) {
        let touch;
        if (e.touches) {
          touch = e.touches[0];
        } else {
          touch = e;
        }

        this.nx = touch.clientX - this.position.x;
        let left = this.dx + this.nx;
        let maxWidth = obj.wrapWidth;

        if (left < 0 || left > maxWidth) return;

        // 修改拖拽图标位置
        obj.btnLeft = left + "px";
        //修改老图宽度
        obj.oldPicWidth = left + "px";
        //修改新图宽度
        obj.newPicWidth = obj.wrapWidth - left + "px";
      }
    },
    end() {
      this.flags = false;
    },
    // 移动端拖拽
    moveReColorMobile(e) {
      this.mobileMove(e, this.reColor);
    },
    // 移动端拖拽
    moveReNewMobile(e) {
      this.mobileMove(e, this.reNew);
    },
  },
};
</script>
<style lang="less" scoped>
// @import "../../../styles/idphoto/m_app.less";
@import "./ts_rephoto.less";
@import "./ts_rephoto_phone.less";
</style>
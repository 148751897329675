<template>
  <div class="m-haoyong-wrap">
    <div class="m-hy-banner">
      <div class="m-banner-logo"></div>
      <div class="m-banner-txt"></div>
    </div>
    <div class="m-hy-top">
      <div class="m-top-pic"></div>
      <div class="m-top-title">好用证件照APP</div>
      <div class="m-top-txt">像自拍一样简单好看</div>
      <!-- <div class="m-top-btn">直接下载</div> -->
    </div>
    <div class="m-hy-main">
      <div class="m-hy-box">
        <div class="m-box-number"></div>
        <div class="m-box-title">发丝级抠图技术</div>
        <div class="m-box-txt">自动识别发丝，抠图效果更显真实</div>
        <div class="m-box-pros">
          <div class="m-hy-pro">自然嫩肤</div>
          <div class="m-hy-pro">磨皮美白</div>
          <div class="m-hy-pro">大眼亮眼</div>
          <div class="m-hy-pro">美颜瘦脸</div>
        </div>
        <div class="m-box-pic"></div>
      </div>
      <div class="m-hy-box second">
        <div class="m-box-number"></div>
        <div class="m-box-title">自然美颜 拍出最美照片</div>
        <div class="m-box-txt">智能美颜算法，让证件照合格又好看</div>
        <div class="m-box-pic"></div>
      </div>
      <div class="m-hy-box third">
        <div class="m-box-number"></div>
        <div class="m-box-title">背景色任意切换</div>
        <div class="m-box-txt">背景色卡随意切换，满足证件照需求</div>
        <div class="m-box-pic"></div>
        <div class="m-box-demos">
          <div class="m-hy-demo"></div>
          <div class="m-hy-demo red"></div>
          <div class="m-hy-demo white"></div>
        </div>
      </div>
      <div class="m-hy-box four">
        <div class="m-box-number"></div>
        <div class="m-box-title">可制作500+种证件照</div>
        <div class="m-box-txt">电子照直接保存 纸质版全国包邮</div>
        <div class="m-box-pic"></div>
        <div class="m-box-tags">
          <span>一寸</span>
          <span>二寸</span>
          <span>全国计算机二级考</span>
          <span>社保证</span>
          <span>驾驶证</span>
          <span>英语四六级</span>
          <span>共计五百多种证件照规格</span>
          <span>可自定义</span>
        </div>
      </div>
    </div>
    <div class="m-hy-footer">
      联系电话：0512-87816685
      <br />联系邮箱：feedback@techtushuo.com
      <br />地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
      <br />Copyright@2020苏州图说科技有限公司
      <a
        href="https://beian.miit.gov.cn"
        target="_blank"
      >苏ICP备20046747号-1</a>
    </div>
  </div>
</template>
<script>
export default {
  name: "m_magic_logo",
  data() {
    return {};
  },
  mounted() {
    let ua = navigator.userAgent.toLowerCase();
    let flag = /mobile|android|iphone|ipad|phone/i.test(ua);
    if (!flag) {
      this.$router
        .push({
          name: "easy_idphoto",
        })
        .catch((err) => {
          err;
        });
    }
  },
  methods: {
    anzhuoDown() {
      location.href = "https://oss.puwu.info/magiclogo/update/水印魔法师.apk";
      //   location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.magiclogo";
    },
  },
};
</script>
<style lang="less" scoped>
// @import "../../../styles/idphoto/m_app.less";
@import "./m_idphoto.less";
</style>